$(function () { 
    // ACCORDION (Multiple Items)
    $('.acc-row .accordion-head').click(function(){
        var parent = $(this).parent();
        parent.siblings().find('.accordion-info').hide();
        parent.siblings().removeClass('active');
        parent.toggleClass('active');
        parent.find('.accordion-info').stop().slideToggle();
    });

    // ACCORDION Toggle (Multiple Items)   
    if($('.acc .acc-head').length > 0)
    {
        $('.acc .acc-head').click(function(){
            var parent = $(this).parent();        
            parent.toggleClass('acc-active');
            parent.find('.acc-info').stop().slideToggle();
        });
    }   
    
}); 



